export enum Experiment {
  EnableOOIInEditor = 'specs.membersArea.framelessEditorPW',
  MoveJoinCommunityCTA = 'specs.profileCardOOI.JoinCommunityCTAUnderMoreActions',
  EnableProfileDetailsEdit = 'specs.profileCardOOI.EnableProfileDetailsEdit',
  MakeProfileCardRemovableInNewMA = 'specs.profileCardOOI.MakeProfileCardRemovableInNewMA',
  UseBlockedCheckFollowButton = 'specs.profileCardOOI.UseBlockedCheckFollowButton',
  EnableCSSIndicators = 'specs.profileCardOOI.EnableCSSIndicators',
  EnableAvifEncoding = 'specs.profileCardOOI.EnableAvifEncoding',
  EnableProfileAlignmentCssVars = 'specs.profileCardOOI.EnableProfileAlignmentCssVars',
  ShowNewNotificationsContent = 'specs.profileCardOOI.showNewNotificationsContent',
  EnableBadgesCSSPB = 'specs.profileCardOOI.EnableBadgesCSSPB',
  RemoveProfileCardContainerMinWidth = 'specs.profileCardOOI.RemoveProfileCardContainerMinWidth',
}
